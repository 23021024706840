/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three"
import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"
import { GLTF } from "three-stdlib"

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh
    Cylinder: THREE.Mesh
    Cylinder001: THREE.Mesh
    Cylinder002: THREE.Mesh
  }
  materials: {
    ["Material.006"]: THREE.MeshStandardMaterial
    ["Material.003"]: THREE.MeshStandardMaterial
    ["Material.002"]: THREE.MeshStandardMaterial
    ["Material.001"]: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements["group"]) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/models/whiteboard.glb") as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.Cube.geometry}
        material={materials["Material.006"]}
        receiveShadow
      />
      <mesh
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.003"]}
        position={[0.17, 0.14, 1.67]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.1}
        castShadow
      />
      <mesh
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.002"]}
        position={[0.12, 0.14, -2.05]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={0.1}
        castShadow
      />
      <mesh
        geometry={nodes.Cylinder002.geometry}
        material={materials["Material.001"]}
        position={[0.15, 0.14, 0]}
        rotation={[-Math.PI / 2, 0, -2.91]}
        scale={0.1}
        castShadow
      />
    </group>
  )
}

useGLTF.preload("/models/whiteboard.glb")
