import * as THREE from "three"
import { Molecule } from "../components/Molecule"

const Caffeine = () => {
  let name = "Caffeine"
  let atoms = [
    {
      position: new THREE.Vector3(-2.561, 1.251, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(-3.261, -1.161, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(1.534, 2.629, 0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(2.247, -2.176, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(-0.438, -2.428, 0.0),
      type: "O",
    },
    {
      position: new THREE.Vector3(2.994, 0.384, 0.0),
      type: "O",
    },
    {
      position: new THREE.Vector3(-0.016, -1.285, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(1.791, 0.208, 0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(-0.911, -0.194, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(-0.403, 1.099, -0.0),
      type: "C",
    },
    {
      position: new THREE.Vector3(-1.445, 1.934, -0.0),
      type: "N",
    },
    {
      position: new THREE.Vector3(0.971, 1.277, -0.0),
      type: "N",
    },
    {
      position: new THREE.Vector3(1.312, -1.048, -0.0),
      type: "N",
    },
    {
      position: new THREE.Vector3(-2.286, -0.068, 0.0),
      type: "N",
    },
    {
      position: new THREE.Vector3(-3.552, 1.68, 0.004),
      type: "H",
    },
    {
      position: new THREE.Vector3(-3.503, -1.433, 1.028),
      type: "H",
    },
    {
      position: new THREE.Vector3(-4.168, -0.84, -0.514),
      type: "H",
    },
    {
      position: new THREE.Vector3(-2.839, -2.025, -0.514),
      type: "H",
    },
    {
      position: new THREE.Vector3(1.673, 2.965, 1.028),
      type: "H",
    },
    {
      position: new THREE.Vector3(2.495, 2.623, -0.514),
      type: "H",
    },
    {
      position: new THREE.Vector3(0.851, 3.307, -0.514),
      type: "H",
    },
    {
      position: new THREE.Vector3(2.478, -2.456, -1.028),
      type: "H",
    },
    {
      position: new THREE.Vector3(3.164, -1.888, 0.513),
      type: "H",
    },
    {
      position: new THREE.Vector3(1.793, -3.024, 0.514),
      type: "H",
    },
  ]
  let bonds = [
    {
      type: "single",
      atoms: [0, 13],
    },
    {
      type: "single",
      atoms: [0, 10],
    },
    {
      type: "single",
      atoms: [0, 14],
    },
    {
      type: "single",
      atoms: [1, 13],
    },
    {
      type: "single",
      atoms: [1, 15],
    },
    {
      type: "single",
      atoms: [1, 16],
    },
    {
      type: "single",
      atoms: [1, 17],
    },
    {
      type: "single",
      atoms: [2, 11],
    },
    {
      type: "single",
      atoms: [2, 18],
    },
    {
      type: "single",
      atoms: [2, 19],
    },
    {
      type: "single",
      atoms: [2, 20],
    },
    {
      type: "single",
      atoms: [3, 12],
    },
    {
      type: "single",
      atoms: [3, 21],
    },
    {
      type: "single",
      atoms: [3, 22],
    },
    {
      type: "single",
      atoms: [3, 23],
    },
    {
      type: "double",
      atoms: [4, 6],
    },
    {
      type: "double",
      atoms: [5, 7],
    },
    {
      type: "single",
      atoms: [6, 8],
    },
    {
      type: "single",
      atoms: [6, 12],
    },
    {
      type: "single",
      atoms: [7, 11],
    },
    {
      type: "single",
      atoms: [7, 7],
    },
    {
      type: "single",
      atoms: [7, 12],
    },

    {
      type: "single",
      atoms: [8, 9],
    },
    {
      type: "single",
      atoms: [8, 6],
    },
    {
      type: "single",
      atoms: [8, 13],
    },

    {
      type: "single",
      atoms: [9, 8],
    },
    {
      type: "single",
      atoms: [9, 11],
    },

    {
      type: "single",
      atoms: [10, 9],
    },
    {
      type: "single",
      atoms: [10, 0],
    },
    {
      type: "single",
      atoms: [11, 9],
    },
    {
      type: "single",
      atoms: [11, 7],
    },
    {
      type: "single",
      atoms: [11, 2],
    },

    {
      type: "single",
      atoms: [12, 6],
    },
    {
      type: "single",
      atoms: [12, 3],
    },
    {
      type: "single",
      atoms: [12, 7],
    },

    {
      type: "single",
      atoms: [13, 8],
    },
    {
      type: "single",
      atoms: [13, 0],
    },
    {
      type: "single",
      atoms: [13, 1],
    },
    {
      type: "aromatic",
      atoms: [0, 10, 9, 8, 13],
    },
    {
      type: "aromatic",
      atoms: [9, 8, 11, 12, 6, 7],
    },
  ]

  return {
    name,
    atoms,
    bonds,
  } as Molecule
}

export default Caffeine
