import { Canvas, useFrame, useThree } from "@react-three/fiber"
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useContext,
  Suspense,
} from "react"
import * as THREE from "three"

import Layout from "../components/Layout"

import { ThemeContext } from "../components/ThemeProvider"
import { swapTheme, Themes } from "../utils/themes"

import * as s from "../styles/home.module.scss"
import { OrbitControls, PerspectiveCamera, useHelper } from "@react-three/drei"
import Molecule from "../components/Molecule"
import { Debug, Physics } from "@react-three/cannon"
import Whiteboard from "../../static/models/Whiteboard"
import { DirectionalLightHelper } from "three"
import {
  useSpring,
  animated,
  update,
  Spring,
  SpringValues,
  SpringValue,
  config,
} from "@react-spring/three"
import Caffeine from "../molecules/Caffeine"
import Cholesterol from "../molecules/Cholesterol"

const LightSwitch = ({
  position = new THREE.Vector3(0, 19.36, -19.55),
  day,
  updateTheme,
}) => {
  const rotation = new THREE.Euler(0, 0, 0)
  const rotationZOn = -Math.PI / 32
  const rotationZOff = -Math.PI + Math.PI / 32

  const { spring }: { spring: SpringValue<number> } = useSpring({
    spring: Number(day),
    config: { tension: 320, friction: 24 },
  })

  const rotationZ = spring.to([0, 1], [rotationZOff, rotationZOn])

  const handleClick = e => {
    swapTheme(updateTheme)
    e.stopPropagation()
  }

  return (
    <>
      <mesh position={position} onClick={handleClick} castShadow receiveShadow>
        <boxBufferGeometry args={[1.2, 6, 4.2]} />
        <meshStandardMaterial color="darkgrey" />
      </mesh>
      <animated.group
        position={position.clone().add(new THREE.Vector3(1.2 / 2 + 0.1, 0, 0))}
        rotation={rotation}
        rotation-z={rotationZ}
        onClick={handleClick}
      >
        <mesh rotation={new THREE.Euler(Math.PI / 2, 0, 0)} castShadow>
          <cylinderGeometry args={[0.4, 0.4, 4]} />
          <meshStandardMaterial color="grey" />
        </mesh>
        <mesh position={new THREE.Vector3(0, 2.4 / 2, -1.2)} castShadow>
          <boxBufferGeometry args={[0.2, 2.4, 0.4]} />
          <meshStandardMaterial color="grey" />
        </mesh>
        <mesh position={new THREE.Vector3(0, 2.4 / 2, 1.2)} castShadow>
          <boxBufferGeometry args={[0.2, 2.4, 0.4]} />
          <meshStandardMaterial color="grey" />
        </mesh>
        <mesh
          rotation={new THREE.Euler(Math.PI / 2, 0, 0)}
          position={new THREE.Vector3(0, 2.4, 0)}
          castShadow
        >
          <cylinderGeometry args={[0.4, 0.4, 3.6]} />
          <meshStandardMaterial color="red" />
        </mesh>
      </animated.group>
    </>
  )
}

const Scene = ({ day, updateTheme }) => {
  const ref = useRef(null!)
  const controls = useRef(null!)

  useEffect(() => {
    // ref.current.shadow.camera.left = 20
    // ref.current.shadow.camera.right = -20
    // ref.current.shadow.camera.top = 20
    // ref.current.shadow.camera.bottom = -20
    ref.current.shadow.mapSize.width = 2048
    ref.current.shadow.mapSize.height = 2048
  }, [])

  const cameraPosition = new THREE.Vector3(34, 22, 0)
  const cameraLookAt = new THREE.Vector3(0, 19.1, 0)

  const lightPosition = new THREE.Vector3(7, 32, -20)

  const lightRotation = new THREE.ArrowHelper(
    new THREE.Vector3().subVectors(lightPosition, cameraLookAt).normalize(),
    lightPosition
  ).rotation

  useThree(({ camera }) => {
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z)
    camera.lookAt(cameraLookAt)
    controls.current?.target.set(cameraLookAt.x, cameraLookAt.y, cameraLookAt.z)
    controls.current?.update()
  })

  const molecule = useRef(null!)

  useFrame(({ clock }) => {
    const a = clock.elapsedTime
    molecule.current.rotation.x = a / 8
    molecule.current.rotation.y = a / 4
  })

  // useHelper(ref, DirectionalLightHelper, 1, "red")

  return (
    <>
      <ambientLight intensity={day ? 0.06 : 0.02} />
      <pointLight
        castShadow
        ref={ref}
        position={lightPosition}
        intensity={day ? 0.4 : 0.8}
      />
      <rectAreaLight
        width={20}
        height={20}
        position={cameraPosition.clone().add(new THREE.Vector3(-10, 4, 0))}
        rotation={[0, Math.PI / 2, -Math.PI / 3]}
        intensity={day ? 1.6 : 0.8}
      />
      <rectAreaLight
        width={20}
        height={20}
        position={cameraPosition.clone().add(new THREE.Vector3(-10, -8, 10))}
        rotation={[-Math.PI / 4, Math.PI / 2, Math.PI / 3]}
        intensity={day ? 2.0 : 0.8}
      />
      {/* <OrbitControls ref={controls} /> */}
      {/* <axesHelper /> */}
      <Whiteboard scale={new THREE.Vector3(10, 10, 10)} />

      <LightSwitch updateTheme={updateTheme} day={day} />

      <group
        scale={0.4}
        ref={molecule}
        position={new THREE.Vector3(10, 26, -2)}
      >
        <Molecule molecule={Caffeine()} />
      </group>
    </>
  )
}

export default function portfolio() {
  const { theme, updateTheme } = useContext(ThemeContext)
  let day = theme === Themes.LIGHT

  return (
    <Layout contentWidth="100vw">
      <div className={s.container}>
        <Canvas shadows>
          <PerspectiveCamera makeDefault fov={60} />
          {/* <Canvas shadows camera={{ position: [0, 26, 0] }}> */}
          <color attach="background" args={[day ? "lightblue" : "black"]} />
          <Suspense fallback={null}>
            <Scene day={day} updateTheme={updateTheme} />{" "}
          </Suspense>
        </Canvas>
      </div>
    </Layout>
  )
}
